:root {
    --primary: rgba(245, 124, 0, 1);
    --primary-hex: #F57C00;
    --transparent-rgba: rgba(0, 0, 0, 0);
}

body {
    padding: 0;
    margin: 0;
    background-color: #F5F5F5;
}

/*Placeholder animation loading*/

.animation-loading {
    position:relative;
    background-color: #CCC;
    height: 6px;
    animation-name: animate;
    animation-duration: 1.25s;
    animation-iteration-count: infinite;
    animation-timing-function: linear;
    background: -webkit-gradient(linear, left top, right top, color-stop(8%, #eeeeee), color-stop(18%, #f5f5f5), color-stop(33%, #eeeeee));
    background: -webkit-linear-gradient(left, #eeeeee 8%, #f5f5f5 18%, #eeeeee 33%);
    background: linear-gradient(to right, #eeeeee 8%, #f5f5f5 18%, #eeeeee 33%);
    -webkit-background-size: 800px 104px;
    transition: all .5s ease-in-out;
}

@keyframes animate {
    0% {
        background-position: -468px 0
    }
    100% {
        background-position: 468px 0
    }
}

/*Glow icon animation*/
.animation-pulse {
    animation: shadow-pulse 1s infinite;
}

@keyframes shadow-pulse {
    0% {
        box-shadow: 0 0 0 0 var(--primary);
    }
    100% {
        box-shadow: 0 0 0 15px var(--transparent-rgba);
    }
}

/*Ring icon animation*/
.animation-ring {
    animation: ring 4s .7s ease-in-out infinite;
}

@keyframes ring {
    0% { transform: rotate(0); }
    1% { transform: rotate(30deg); }
    3% { transform: rotate(-28deg); }
    5% { transform: rotate(34deg); }
    7% { transform: rotate(-32deg); }
    9% { transform: rotate(30deg); }
    11% { transform: rotate(-28deg); }
    13% { transform: rotate(26deg); }
    15% { transform: rotate(-24deg); }
    17% { transform: rotate(22deg); }
    19% { transform: rotate(-20deg); }
    21% { transform: rotate(18deg); }
    23% { transform: rotate(-16deg); }
    25% { transform: rotate(14deg); }
    27% { transform: rotate(-12deg); }
    29% { transform: rotate(10deg); }
    31% { transform: rotate(-8deg); }
    33% { transform: rotate(6deg); }
    35% { transform: rotate(-4deg); }
    37% { transform: rotate(2deg); }
    39% { transform: rotate(-1deg); }
    41% { transform: rotate(1deg); }

    43% { transform: rotate(0); }
    100% { transform: rotate(0); }
}

/*Media Responsive*/
/*TODO: colocar responsivo o video ao fundo*/
#videoBG, #gif-bg {
    position:fixed;
    z-index: -1;
}
/*#gif-bg {
    filter: blur(8px);
    -webkit-filter: blur(2px);
}*/
@media (min-aspect-ratio: 16/9) {
    #videoBG, #gif-bg {
        width:100%;
        height: auto;
    }
}
@media (max-aspect-ratio: 16/9) {
    #videoBG, #gif-bg {
        width:auto;
        height: 100%;
    }
}

/*Highcharts*/
.highcharts-menu-item {
    font-size: 13px !important;
}
