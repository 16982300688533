:root {
    --primary: rgba(245, 124, 0, 1);
    --primary-hex: #F57C00;
    --transparent-rgba: rgba(0, 0, 0, 0);
}

body {
    padding: 0;
    margin: 0;
    background-color: #F5F5F5;
}

/*Placeholder animation loading*/

.animation-loading {
    position:relative;
    background-color: #CCC;
    height: 6px;
    -webkit-animation-name: animate;
            animation-name: animate;
    -webkit-animation-duration: 1.25s;
            animation-duration: 1.25s;
    -webkit-animation-iteration-count: infinite;
            animation-iteration-count: infinite;
    -webkit-animation-timing-function: linear;
            animation-timing-function: linear;
    background: -webkit-gradient(linear, left top, right top, color-stop(8%, #eeeeee), color-stop(18%, #f5f5f5), color-stop(33%, #eeeeee));
    background: linear-gradient(to right, #eeeeee 8%, #f5f5f5 18%, #eeeeee 33%);
    -webkit-background-size: 800px 104px;
    -webkit-transition: all .5s ease-in-out;
    transition: all .5s ease-in-out;
}

@-webkit-keyframes animate {
    0% {
        background-position: -468px 0
    }
    100% {
        background-position: 468px 0
    }
}

@keyframes animate {
    0% {
        background-position: -468px 0
    }
    100% {
        background-position: 468px 0
    }
}

/*Glow icon animation*/
.animation-pulse {
    -webkit-animation: shadow-pulse 1s infinite;
            animation: shadow-pulse 1s infinite;
}

@-webkit-keyframes shadow-pulse {
    0% {
        box-shadow: 0 0 0 0 rgba(245, 124, 0, 1);
        box-shadow: 0 0 0 0 var(--primary);
    }
    100% {
        box-shadow: 0 0 0 15px rgba(0, 0, 0, 0);
        box-shadow: 0 0 0 15px var(--transparent-rgba);
    }
}

@keyframes shadow-pulse {
    0% {
        box-shadow: 0 0 0 0 rgba(245, 124, 0, 1);
        box-shadow: 0 0 0 0 var(--primary);
    }
    100% {
        box-shadow: 0 0 0 15px rgba(0, 0, 0, 0);
        box-shadow: 0 0 0 15px var(--transparent-rgba);
    }
}

/*Ring icon animation*/
.animation-ring {
    -webkit-animation: ring 4s .7s ease-in-out infinite;
            animation: ring 4s .7s ease-in-out infinite;
}

@-webkit-keyframes ring {
    0% { -webkit-transform: rotate(0); transform: rotate(0); }
    1% { -webkit-transform: rotate(30deg); transform: rotate(30deg); }
    3% { -webkit-transform: rotate(-28deg); transform: rotate(-28deg); }
    5% { -webkit-transform: rotate(34deg); transform: rotate(34deg); }
    7% { -webkit-transform: rotate(-32deg); transform: rotate(-32deg); }
    9% { -webkit-transform: rotate(30deg); transform: rotate(30deg); }
    11% { -webkit-transform: rotate(-28deg); transform: rotate(-28deg); }
    13% { -webkit-transform: rotate(26deg); transform: rotate(26deg); }
    15% { -webkit-transform: rotate(-24deg); transform: rotate(-24deg); }
    17% { -webkit-transform: rotate(22deg); transform: rotate(22deg); }
    19% { -webkit-transform: rotate(-20deg); transform: rotate(-20deg); }
    21% { -webkit-transform: rotate(18deg); transform: rotate(18deg); }
    23% { -webkit-transform: rotate(-16deg); transform: rotate(-16deg); }
    25% { -webkit-transform: rotate(14deg); transform: rotate(14deg); }
    27% { -webkit-transform: rotate(-12deg); transform: rotate(-12deg); }
    29% { -webkit-transform: rotate(10deg); transform: rotate(10deg); }
    31% { -webkit-transform: rotate(-8deg); transform: rotate(-8deg); }
    33% { -webkit-transform: rotate(6deg); transform: rotate(6deg); }
    35% { -webkit-transform: rotate(-4deg); transform: rotate(-4deg); }
    37% { -webkit-transform: rotate(2deg); transform: rotate(2deg); }
    39% { -webkit-transform: rotate(-1deg); transform: rotate(-1deg); }
    41% { -webkit-transform: rotate(1deg); transform: rotate(1deg); }

    43% { -webkit-transform: rotate(0); transform: rotate(0); }
    100% { -webkit-transform: rotate(0); transform: rotate(0); }
}

@keyframes ring {
    0% { -webkit-transform: rotate(0); transform: rotate(0); }
    1% { -webkit-transform: rotate(30deg); transform: rotate(30deg); }
    3% { -webkit-transform: rotate(-28deg); transform: rotate(-28deg); }
    5% { -webkit-transform: rotate(34deg); transform: rotate(34deg); }
    7% { -webkit-transform: rotate(-32deg); transform: rotate(-32deg); }
    9% { -webkit-transform: rotate(30deg); transform: rotate(30deg); }
    11% { -webkit-transform: rotate(-28deg); transform: rotate(-28deg); }
    13% { -webkit-transform: rotate(26deg); transform: rotate(26deg); }
    15% { -webkit-transform: rotate(-24deg); transform: rotate(-24deg); }
    17% { -webkit-transform: rotate(22deg); transform: rotate(22deg); }
    19% { -webkit-transform: rotate(-20deg); transform: rotate(-20deg); }
    21% { -webkit-transform: rotate(18deg); transform: rotate(18deg); }
    23% { -webkit-transform: rotate(-16deg); transform: rotate(-16deg); }
    25% { -webkit-transform: rotate(14deg); transform: rotate(14deg); }
    27% { -webkit-transform: rotate(-12deg); transform: rotate(-12deg); }
    29% { -webkit-transform: rotate(10deg); transform: rotate(10deg); }
    31% { -webkit-transform: rotate(-8deg); transform: rotate(-8deg); }
    33% { -webkit-transform: rotate(6deg); transform: rotate(6deg); }
    35% { -webkit-transform: rotate(-4deg); transform: rotate(-4deg); }
    37% { -webkit-transform: rotate(2deg); transform: rotate(2deg); }
    39% { -webkit-transform: rotate(-1deg); transform: rotate(-1deg); }
    41% { -webkit-transform: rotate(1deg); transform: rotate(1deg); }

    43% { -webkit-transform: rotate(0); transform: rotate(0); }
    100% { -webkit-transform: rotate(0); transform: rotate(0); }
}

/*Media Responsive*/
/*TODO: colocar responsivo o video ao fundo*/
#videoBG, #gif-bg {
    position:fixed;
    z-index: -1;
}
/*#gif-bg {
    filter: blur(8px);
    -webkit-filter: blur(2px);
}*/
@media (min-aspect-ratio: 16/9) {
    #videoBG, #gif-bg {
        width:100%;
        height: auto;
    }
}
@media (max-aspect-ratio: 16/9) {
    #videoBG, #gif-bg {
        width:auto;
        height: 100%;
    }
}

/*Highcharts*/
.highcharts-menu-item {
    font-size: 13px !important;
}
